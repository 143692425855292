.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 991px){
  .navbar-brand-centered .img-responsive {
    display: block;
    max-width: 70%;
    height: auto;
  }
  .nav>li {
    text-align: center;
    font-size: 20px;
    border-bottom: solid 1px #dedede;
  }
  .navbar-nav > li > a {
    line-height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}



/* Custom Styles */
.contactUsMap{
  width:100%;
  min-height:350px;
}

footer .img-responsive {
  display: block;
  max-width: 150px;
  height: auto;
}

@media (min-width: 1200px){
  .align-center{
      left:50%;
      margin-left: -20%;
  }
  .contactUsMap{
      width:600px;
      height:550px;
  }
}
